import { db } from "../firebase/firebase-config";
import { types } from "../types/type";
import axios from "axios";
import Swal from "sweetalert2";

export const getYears = async (dispatch) => {
  const resDb = await db.collection("cars_tree").get();
  let years = [];
  resDb.forEach((year) => {
    years.push(year.data().sources[0].data.year);
  });
  dispatch(startGetYears(years));
};

export const startGetYears = (years) => ({
  type: types.GetYears,
  payload: {
    years,
  },
});

export const getBrands = async (dispatch, año) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_brands?year=${año}`
    )
    .then((resp) => {
      dispatch(startGetBrands(resp.data.brands));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const startGetBrands = (brands) => ({
  type: types.GetBrands,
  payload: {
    brands,
  },
});

export const startAcumuladorAño = (año) => ({
  type: types.AcumuladorAño,
  payload: {
    año,
  },
});

export const startAcumuladorMarca = (marca, id) => ({
  type: types.AcumuladorMarca,
  payload: {
    marca,
    id,
  },
});

export const startAcumuladorModelo = (modelo, id) => ({
  type: types.AcumuladorModelo,
  payload: {
    modelo,
    id,
  },
});

export const startAcumuladorVersion = (version, id) => ({
  type: types.AcumuladorVersion,
  payload: {
    version,
    id,
  },
});

export const startAcumuladorKilometros = (kilometros) => ({
  type: types.AcumuladorKilometros,
  payload: {
    kilometros,
  },
});

export const startAcumuladorGNC = (gnc) => ({
  type: types.AcumuladorGNC,
  payload: {
    gnc,
  },
});

export const startAcumuladorTemplate = (template) => ({
  type: types.AcumuladorTemplate,
  payload: {
    template,
  },
});

export const getModels = async (dispatch, marca, año) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_models?year=${año}&brand=${marca}`
    )
    .then((resp) => {
      dispatch(startGetModels(resp.data.models));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const startGetModels = (models) => ({
  type: types.GetModels,
  payload: {
    models,
  },
});

export const getVersion = async (dispatch, año, marca, model) => {
  axios
    .get(
      `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/get_versions?year=${año}&brand=${marca}&model=${model}`
    )
    .then((resp) => {
      console.log(resp.data.versions);
      dispatch(startGetVersion(resp.data.versions));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const startGetVersion = (versions) => ({
  type: types.GetVersion,
  payload: {
    versions,
  },
});

export const sendKmsRedux = (valorKms) => ({
  type: types.SendKms,
  payload: {
    valorKms,
  },
});

export const enviarDatosAuto = async (
  brand,
  brand_id,
  model,
  model_id,
  version,
  version_id,
  year,
  gnc,
  kms,
  deal_id,
  valueTemplate,
  navigate,
  dispatch
) => {
  const intValue = parseInt(kms.replace(/\./g, ""), 10);

  const data = {
    brand: brand,
    brand_id: brand_id,
    model: model,
    model_id: model_id,
    version: version,
    version_id: version_id,
    year: year,
    gnc: gnc === "Si" ? true : false,
    kms: intValue,
    deal_id: deal_id,
    send_template: valueTemplate === "Si" ? true : false,
    source: "landing_san_jorge",
  };

  axios
    .post(
      `https://infoauto-348420.rj.r.appspot.com/deal/${deal_id}/carAuta`,
      data
    )
    .then((resp) => {
      dispatch(startLoading(false, resp.status));
      navigate(`/resumen/${deal_id}`);
      Swal.fire(
        "¡Datos enviados!",
        "Gracias, le enviaremos una oferta inicial a la brevedad.",
        "success"
      );
      console.log(resp);
    })
    .catch((e) => {
      const error = JSON.parse(e.request.response);
      dispatch(startLoading(error.message, e.request.status));
      console.log(e);
    });
};

export const startLoading = (loading, status) => ({
  type: types.Loading,
  payload: {
    loading,
    status,
  },
});

export const cargarDatosAuto = async (
  brand,
  brand_id,
  model,
  model_id,
  version,
  version_id,
  year,
  kms,
  deal_id
) => {
  const data = {
    brand: brand,
    brand_id: brand_id,
    model: model,
    model_id: model_id,
    version: version,
    version_id: version_id,
    year: year,
    kms: kms,
    deal_id: deal_id,
  };

  axios
    .post(
      `https://infoauto-348420.rj.r.appspot.com/deal/${deal_id}/car/load`,
      data
    )
    .then((resp) => {
      console.log(resp);
    })
    .catch((e) => {
      console.log(e);
    });
};
