import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getModels,
  sendKmsRedux,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
  startGetModels,
  startGetVersion,
} from "../../actions/pasosCotizar";
import Hidden from "@mui/material/Hidden";

export const Marca = () => {
  const dispatch = useDispatch();
  const { brands, acumulador, valorKms } = useSelector(
    (state) => state.inputReducer
  );

  const [mostrarSelect, setmostrarSelect] = useState(false);
  const [marcaState, setmarcaState] = useState();

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorMarca(undefined));
    dispatch(startAcumuladorModelo(undefined));
    dispatch(startAcumuladorVersion(undefined));
    dispatch(sendKmsRedux(undefined));
    dispatch(startGetModels([]));
    dispatch(startGetVersion([]));
  };

  const seleccionarMarca = (id, name) => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorMarca(name, id));
    getModels(dispatch, name, acumulador.año);
    setmarcaState(name);
    if (
      acumulador !== undefined &&
      (acumulador.modelo !== undefined ||
        acumulador.version !== undefined ||
        valorKms !== undefined)
    ) {
      dispatch(startAcumuladorModelo(""));
      dispatch(startAcumuladorVersion(""));
      dispatch(sendKmsRedux(""));
    }
  };

  return (
    <>
      <Hidden mdDown>
        <div className="container-input-scroll">
          {acumulador !== undefined && acumulador.año !== undefined ? (
            <>
              {acumulador.marca !== undefined && acumulador.marca !== "" ? (
                <div
                  className="btn-hecho animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  {acumulador.marca.toLowerCase()}
                </div>
              ) : (
                <div
                  className="btn-activo animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  Marca
                </div>
              )}
            </>
          ) : (
            <div className="btn-disabled">Marca</div>
          )}
          {acumulador !== undefined &&
          acumulador.año !== undefined &&
          acumulador.marca === undefined ? (
            <div className="select-input">
              {brands !== undefined ? (
                <>
                  {brands.map((info) => (
                    <>
                      <Hidden smDown>
                        <p
                          className="text-opciones animate__fadeInDownBig"
                          onClick={() => seleccionarMarca(info.id, info.name)}
                          key={info.id}
                        >
                          {info.name.toLowerCase()}
                        </p>
                      </Hidden>
                      <Hidden smUp>
                        <p
                          className="text-opciones animate__fadeInDownBig"
                          onClick={() => seleccionarMarca(info.id, info.name)}
                          key={info.id}
                        >
                          {info.name.toLowerCase()}
                        </p>
                      </Hidden>
                    </>
                  ))}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="container-input-scroll">
          {acumulador !== undefined &&
          acumulador.año !== undefined &&
          acumulador.año !== "" ? (
            <>
              {acumulador.marca !== undefined && acumulador.marca !== "" ? (
                <div
                  className="btn-hecho"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  {acumulador.marca.toLowerCase()}
                </div>
              ) : (
                <div
                  className="btn-activo"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  Marca
                </div>
              )}
            </>
          ) : (
            <div
              className="btn-disabled"
              style={{ width: "160px", height: "33px", fontSize: "16px" }}
            >
              Marca
            </div>
          )}
          {mostrarSelect ||
          (acumulador !== undefined &&
            acumulador.año !== "" &&
            acumulador.marca === undefined) ||
          (acumulador !== undefined &&
            acumulador.año !== "" &&
            acumulador.marca === "") ? (
            // {mostrarSelect ||
            // (acumulador !== undefined &&
            //   acumulador.año !== undefined &&
            //   acumulador.año !== "") ? (
            <div className="select-input">
              {brands !== undefined ? (
                <>
                  {brands.map((info) => (
                    <p
                      className="text-opciones animate__fadeInDownBig"
                      onClick={() => seleccionarMarca(info.id, info.name)}
                      key={info.id}
                    >
                      {info.name.toLowerCase()}
                    </p>
                  ))}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </Hidden>
    </>
  );
};
