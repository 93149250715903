import { types } from "../types/type";

export const isTablet = (valor) => ({
  type: types.EsTablet,
  payload: {
    valor,
  },
});

export const isCelular = (valor) => ({
  type: types.EsCelular,
  payload: {
    valor,
  },
});

export const cerrarKms = (close) => ({
  type: types.CloseKms,
  payload: {
    close,
  },
});

export const estaActivo = (activo) => ({
  type: types.EstaActivo,
  payload: {
    activo,
  },
});

export const estaEscrito = (escrito) => ({
  type: types.Escribiendo,
  payload: {
    escrito,
  },
});
