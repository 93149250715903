import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Cargar } from "../components/Cargar/Cargar";
import { Cotizar } from "../components/Cotizar/Cotizar";
import { Resumen } from "../components/Resumen/Resumen";

export const AppRouter = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/:deal_id" element={<Cotizar />} />
          <Route exact path="/cargar/:deal_id" element={<Cargar />} />
          <Route exact path="/resumen/:deal_id" element={<Resumen />} />
        </Routes>
      </Router>
    </div>
  );
};
