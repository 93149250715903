import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVersion,
  sendKmsRedux,
  startAcumuladorModelo,
  startAcumuladorVersion,
  startGetVersion,
} from "../../actions/pasosCotizar";
import Hidden from "@mui/material/Hidden";

export const Modelo = () => {
  const { acumulador, models, valorKms } = useSelector(
    (state) => state.inputReducer
  );

  const dispatch = useDispatch();

  const [mostrarSelect, setmostrarSelect] = useState(false);

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorModelo(undefined));
    dispatch(startAcumuladorVersion(undefined));
    dispatch(sendKmsRedux(undefined));
    dispatch(startGetVersion([]));
  };

  const seleccionarModelo = (id, name) => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorModelo(name, id));
    getVersion(dispatch, acumulador.año, acumulador.marca, name);
    if (acumulador.version !== undefined && valorKms !== undefined) {
      dispatch(startAcumuladorVersion(""));
      dispatch(sendKmsRedux(""));
    }
  };

  return (
    <>
      <Hidden mdDown>
        <div className="container-input-scroll">
          {acumulador !== undefined &&
          acumulador.marca !== undefined &&
          acumulador.marca !== "" ? (
            <>
              {acumulador.modelo !== undefined && acumulador.modelo !== "" ? (
                <div
                  className="btn-hecho animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  {acumulador.modelo.toLowerCase()}
                </div>
              ) : (
                <div
                  className="btn-activo animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  Modelo
                </div>
              )}
            </>
          ) : (
            <div className="btn-disabled">Modelo</div>
          )}
          {acumulador !== undefined &&
          acumulador.marca !== undefined &&
          (acumulador.modelo === undefined || acumulador.modelo === "") ? (
            <>
              {models !== undefined ? (
                <div
                  className={
                    models.length < 8
                      ? "select-input-ajustable"
                      : "select-input"
                  }
                >
                  <>
                    {models.map((info) => (
                      <p
                        className="text-opciones animate__fadeInDownBig"
                        onClick={() => seleccionarModelo(info.id, info.name)}
                        key={info.id}
                      >
                        {info.name.toLowerCase()}
                      </p>
                    ))}
                  </>
                </div>
              ) : // <div className="select-input-ajustable">
              //   <CircularProgress />
              // </div>
              null}
            </>
          ) : null}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="container-input-scroll">
          {acumulador !== undefined &&
          acumulador.marca !== undefined &&
          acumulador.marca !== "" ? (
            <>
              {acumulador.modelo !== undefined && acumulador.modelo !== "" ? (
                <div
                  className="btn-hecho"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  {acumulador.modelo}
                </div>
              ) : (
                <div
                  className="btn-activo"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  Modelo
                </div>
              )}
            </>
          ) : (
            <div
              className="btn-disabled"
              style={{ width: "160px", height: "33px", fontSize: "16px" }}
            >
              Modelo
            </div>
          )}
          {mostrarSelect ||
          (acumulador !== undefined &&
            acumulador.marca !== undefined &&
            acumulador.marca !== "" &&
            acumulador.modelo === undefined) ||
          (acumulador !== undefined &&
            acumulador.marca !== undefined &&
            acumulador.marca !== "" &&
            acumulador.modelo === "") ? (
            // {mostrarSelect ||
            // (acumulador !== undefined &&
            //   acumulador.marca !== undefined &&
            //   acumulador.marca !== "") ? (
            <>
              {models !== undefined ? (
                <div
                  className={
                    models.length < 8
                      ? "select-input-ajustable"
                      : "select-input"
                  }
                >
                  <>
                    {models.map((info) => (
                      <p
                        className="text-opciones animate__fadeInDownBig"
                        onClick={() => seleccionarModelo(info.id, info.name)}
                        key={info.id}
                      >
                        {info.name.toLowerCase()}
                      </p>
                    ))}
                  </>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </Hidden>
    </>
  );
};
