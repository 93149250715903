import { Grid } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import React, { useEffect } from "react";
import LogoSanJorge from "../../images/logo-san-jorge.png";
import ArrowBack from "../../images/arrow-back.png";
import { Año } from "../Año/Año";
import { Kilometros } from "../Kilometros/Kilometros";
import { Marca } from "../Marca/Marca";
import { Modelo } from "../Modelo/Modelo";
import { Version } from "../Version/Version";
import "../Cotizar/Cotizar.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isTablet } from "../../actions/pasosTablet";
import {
  sendKmsRedux,
  startAcumuladorAño,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
} from "../../actions/pasosCotizar";
import "animate.css";
import { BotonCargar } from "../ButtonContinuar/BotonCargar";
import { KilometrosCargar } from "../KilometrosCargar/KilometrosCargar";

export const Cargar = () => {
  const { deal_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { editar } = useSelector((state) => state.validateReducer);
  const { valor, acumulador } = useSelector((state) => state.inputReducer);

  useEffect(() => {
    axios
      .get(` https://infoauto-348420.rj.r.appspot.com/deal/${deal_id}/car`)
      .then((resp) => {
        let lengthOfObject = Object.keys(resp.data.car).length;
        if (editar === "editar") {
        } else if (lengthOfObject !== 0) {
          navigate(`/resumen/${deal_id}`);
        }
      })
      .catch((e) => {
        console.log("ERROR", e);
      });
  }, []);

  const volverAño = () => {
    dispatch(isTablet(""));
    dispatch(startAcumuladorAño(""));
    dispatch(startAcumuladorMarca(undefined));
    dispatch(startAcumuladorModelo(undefined));
    dispatch(startAcumuladorVersion(undefined));
    dispatch(sendKmsRedux(undefined));
  };

  const closeKm = () => {
    // if (activo === "activo" || close === false) {
    //   dispatch(cerrarKms(true));
    //   dispatch(estaActivo(""));
    // }
  };

  const backStepOne = () => {
    dispatch(startAcumuladorAño(""));
  };

  const backStepTwo = () => {
    dispatch(startAcumuladorMarca(""));
  };

  const backStepTres = () => {
    dispatch(startAcumuladorModelo(""));
    dispatch(startAcumuladorVersion(""));
  };

  const backStepFour = () => {
    dispatch(startAcumuladorVersion(""));
  };

  return (
    <>
      <Grid
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="container-cotizar"
        onClick={closeKm}
      >
        <Grid xs={12} container alignItems="center" justifyContent="center">
          <img
            src={LogoSanJorge}
            alt="logo-san-jorge"
            className="logo-san-jorge"
          />
        </Grid>
        <Grid
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <p className="text-cotiza">Cotiza tu auto</p>
          <p className="text-ingresa">
            Ingresa los datos y encuentra una de las mejores ofertas
          </p>
          <Hidden mdUp>
            {valor === "tablet" ? (
              <div className="container-paso-arrow">
                <img
                  src={ArrowBack}
                  alt="arrow-back"
                  className="arrow-back"
                  onClick={volverAño}
                />
                <p className="text-steps">
                  Editar <strong>año</strong>
                </p>
              </div>
            ) : null}
          </Hidden>
          <Hidden smUp>
            {acumulador !== undefined &&
            acumulador.año !== undefined &&
            acumulador.año !== "" &&
            valor === "celular" ? (
              <>
                {acumulador !== undefined &&
                acumulador.año !== undefined &&
                acumulador.marca !== undefined &&
                acumulador.marca !== "" &&
                valor === "celular" ? (
                  <>
                    {acumulador !== undefined &&
                    acumulador.año !== undefined &&
                    acumulador.marca !== undefined &&
                    acumulador.marca !== "" &&
                    acumulador.modelo !== undefined &&
                    acumulador.modelo !== "" &&
                    valor === "celular" ? (
                      <>
                        {acumulador !== undefined &&
                        acumulador.año !== undefined &&
                        acumulador.año !== "" &&
                        acumulador.marca !== undefined &&
                        acumulador.marca !== "" &&
                        acumulador.modelo !== undefined &&
                        acumulador.modelo !== "" &&
                        acumulador.version !== undefined &&
                        acumulador.version !== "" &&
                        valor === "celular" ? (
                          <div className="container-paso-arrow">
                            <img
                              src={ArrowBack}
                              alt="arrow-back"
                              className="arrow-back"
                              onClick={backStepFour}
                            />
                            <p className="text-steps">
                              Paso <strong>5</strong> de <strong>5</strong>
                            </p>
                          </div>
                        ) : (
                          <div className="container-paso-arrow">
                            <img
                              src={ArrowBack}
                              alt="arrow-back"
                              className="arrow-back"
                              onClick={backStepTres}
                            />
                            <p className="text-steps">
                              Paso <strong>4</strong> de <strong>5</strong>
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="container-paso-arrow">
                        <img
                          src={ArrowBack}
                          alt="arrow-back"
                          className="arrow-back"
                          onClick={backStepTwo}
                        />
                        <p className="text-steps">
                          Paso <strong>3</strong> de <strong>5</strong>
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="container-paso-arrow">
                    <img
                      src={ArrowBack}
                      alt="arrow-back"
                      className="arrow-back"
                      onClick={backStepOne}
                    />
                    <p className="text-steps">
                      Paso <strong>2</strong> de <strong>5</strong>
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="container-paso-arrow">
                <p className="text-steps">
                  Paso <strong>1</strong> de <strong>5</strong>
                </p>
              </div>
            )}
          </Hidden>
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            container
            alignItems="start"
            justifyContent="space-evenly"
            style={{ marginTop: "30px" }}
          >
            <Año />
            <Marca />
            <Modelo />
            <Version />
            <Kilometros />
            <BotonCargar />
          </Grid>
        </Hidden>
        <Hidden mdUp smDown>
          <Grid
            item
            xs={12}
            container
            alignItems="start"
            justifyContent="space-evenly"
            style={{ marginTop: "30px" }}
          >
            {valor === "tablet" ? (
              <>
                <Marca />
                <Modelo />
                <Version />
                <KilometrosCargar />
              </>
            ) : (
              <>
                <Año />
                <Marca />
                <Modelo />
                <Version />
              </>
            )}
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            container
            alignItems="start"
            justifyContent="space-evenly"
            style={{ marginTop: "30px" }}
          >
            {acumulador !== undefined &&
            acumulador.año !== undefined &&
            acumulador.año !== "" &&
            valor === "celular" ? (
              <>
                {acumulador !== undefined &&
                acumulador.año !== undefined &&
                acumulador.año !== "" &&
                acumulador.marca !== undefined &&
                acumulador.marca !== "" &&
                valor === "celular" ? (
                  <>
                    {acumulador !== undefined &&
                    acumulador.año !== undefined &&
                    acumulador.año !== "" &&
                    acumulador.marca !== undefined &&
                    acumulador.marca !== "" &&
                    acumulador.modelo !== undefined &&
                    acumulador.modelo !== "" &&
                    valor === "celular" ? (
                      <>
                        {acumulador !== undefined &&
                        acumulador.año !== undefined &&
                        acumulador.año !== "" &&
                        acumulador.marca !== undefined &&
                        acumulador.marca !== "" &&
                        acumulador.modelo !== undefined &&
                        acumulador.modelo !== "" &&
                        acumulador.version !== undefined &&
                        acumulador.version !== "" &&
                        valor === "celular" ? (
                          <KilometrosCargar />
                        ) : (
                          <>
                            <Version />
                            <Kilometros />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Modelo />
                        <Version />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Marca />
                    <Modelo />
                  </>
                )}
              </>
            ) : (
              <>
                <Año />
                <Marca />
              </>
            )}
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};
