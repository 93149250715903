import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { cargarDatosAuto } from "../../actions/pasosCotizar";
import Swal from "sweetalert2";

export const BotonCargarTablet = () => {
  const { acumulador, valorKms } = useSelector((state) => state.inputReducer);
  const { deal_id } = useParams();
  const navigate = useNavigate();

  const enviarDatos = () => {
    cargarDatosAuto(
      acumulador.marca,
      acumulador.brand_id,
      acumulador.modelo,
      acumulador.model_id,
      acumulador.version,
      acumulador.version_id,
      acumulador.año,
      valorKms,
      deal_id
    );
    navigate(`/resumen/${deal_id}`);
    Swal.fire(
      "¡Datos cargados!",
      "Gracias, le enviaremos una oferta inicial a la brevedad.",
      "success"
    );
  };

  return (
    <>
      {valorKms !== undefined &&
      valorKms > 0 &&
      acumulador.gnc !== undefined &&
      acumulador.gnc !== "" ? (
        <div className="btn-continuar-activo-tablet" onClick={enviarDatos}>
          Cargar
        </div>
      ) : (
        <div className="btn-continuar-disabled-tablet">Cargar</div>
      )}
    </>
  );
};
