import React, { useEffect, useState } from "react";
import LogSanJorge from "../../images/logo-san-jorge.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editarAuto, getDeal } from "../../actions/validateDeal";

export const Resumen = () => {
  const { marca, modelo, version, año } = useSelector(
    (state) => state.validateReducer
  );
  const { acumulador } = useSelector((state) => state.inputReducer);

  const dispatch = useDispatch();
  const { deal_id } = useParams();

  const navigate = useNavigate();

  const [showModalEditar, setshowModalEditar] = useState(false);
  const [showModalCancelar, setshowModalCancelar] = useState(false);

  useEffect(() => {
    getDeal(dispatch, deal_id, navigate);
  }, []);

  const modalEditar = () => {
    setshowModalEditar(!showModalEditar);
  };

  const modalCancelar = () => {
    // setshowModalCancelar(!showModalCancelar);
  };

  const editarAutoCotizacion = () => {
    dispatch(editarAuto("editar"));
    navigate(`/${deal_id}`);
  };

  const cancelarTurnos = () => {
    // cancelarTurnoDb(deal_id);
    // Swal.fire("Cancelado", "Turno cancelado correctamente.", "success");
    // navigate("/");
    // dispatch(sendPutTurnos("cancelar"));
    // navigate(`/turnos-san-jorge/${deal_id}`);
  };

  return (
    <div className="containerPrincipalSJ">
      <img src={LogSanJorge} alt="logo-kardur" className="logoSanJorge" />
      {showModalEditar ? (
        <div className="containerModal animate__fadeIn">
          <p>¿Querés editar los datos de tu auto?</p>
          <div className="containerButtonsModal">
            <button className="buttonNoSJ" onClick={modalEditar}>
              No
            </button>
            <button
              className="buttonModificarSJ"
              onClick={editarAutoCotizacion}
            >
              Editar auto
            </button>
          </div>
        </div>
      ) : showModalCancelar ? (
        <div className="containerModal animate__fadeIn">
          <p>¿Querés cancelar tu turno?</p>
          <div className="containerButtonsModal">
            <button className="buttonNoSJ" onClick={modalCancelar}>
              No
            </button>
            <button className="buttonModificarSJ" onClick={cancelarTurnos}>
              Cancelar turno
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="cardResumenSJ animate__fadeIn">
            <p className="titleInicial">
              Gracias, le enviaremos una oferta inicial a la brevedad.
            </p>
            <div className="containerInspeccion">
              {/* <p className="textInspeccion">Inspeccion tecnica</p> */}
              <div className="containerFechaHorarioText">
                <div>
                  {/* <img src={IconoCalendar} className="iconoCalendar" /> */}
                  {/* <p className="textFechaHorario">
                    Horario */}
                  {/* {diaSeleccionado !== ""
                      ? diaSeleccionado
                      : diaSeleccionadoNew} */}
                  {/* </p> */}
                </div>
                <div>
                  {/* <img src={IconoHorario} className="iconoHorario" /> */}
                  {/* <p className="textFechaHorario">
                    Horario */}
                  {/* {horarioSeleccionado !== ""
                      ? horarioSeleccionado
                      : horarioSeleccionadoNew} */}
                  {/* </p> */}
                </div>
              </div>
            </div>
            <div className="containerLocation">
              {/* <img src={IconoLocation} alt="icono-location-kardur" /> */}
              {/* <p className="textFechaHorario">
                Senillosa 1550, Capital federal
              </p> */}
            </div>
            <p className="textVehiculo">Vehiculo</p>
            <div className="containerMarcaModeloVersion">
              <p
                className="textInspeccion"
                style={{ marginLeft: "40px", marginRight: "20px" }}
              >
                {acumulador !== undefined && acumulador.marca !== undefined
                  ? acumulador.marca
                  : marca}
              </p>
              {/* <p
                className="textInspeccion"
                style={{ marginLeft: "40px", marginRight: "20px" }}
              >
                {marca}
              </p> */}
              {/* <p className="textModelo">{modelo}</p> */}
              <p className="textModelo">
                {" "}
                {acumulador !== undefined && acumulador.modelo !== undefined
                  ? acumulador.modelo
                  : modelo}
              </p>
              <p
                className="textInspeccion"
                style={{ marginLeft: "5px", marginRight: "5px" }}
              >
                -
              </p>
              <p className="textModelo">
                {acumulador !== undefined && acumulador.version !== undefined
                  ? acumulador.version
                  : version}
              </p>
              {/* <p className="textModelo">{version}</p> */}
            </div>
            <div style={{ display: "flex" }}>
              <p className="textInspeccion" style={{ marginTop: "10px" }}>
                {acumulador !== undefined && acumulador.año !== undefined
                  ? acumulador.año
                  : año}
              </p>
              {/* <p
                className="textInspeccion"
                style={{ marginTop: "10px", marginLeft: "25px" }}
              >
                {acumulador !== undefined && acumulador.kms !== undefined
                  ? acumulador.kms
                  : kms}{" "}
                kms
              </p> */}
            </div>
            {/* <p className="textInspeccion" style={{ marginTop: "10px" }}>
              {año}
            </p> */}
            <div className="containerButtons">
              <button className="buttonEditarTurnoSJ" onClick={modalEditar}>
                Editar datos del auto
              </button>
              {/* <button className="buttonCancelarTurnoSJ" onClick={modalCancelar}>
                Cancelar turno
              </button> */}
            </div>
          </div>
          {/* <p className="textAclaracion">
            Los turnos tienen una tolerancia de hasta 15 minutos*
          </p>
          <p className="textAclaracion">
            Concurrir con toda la documentación del vehícula requerida*
          </p> */}
        </>
      )}
    </div>
  );
};
