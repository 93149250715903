import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  enviarDatosAuto,
  startAcumuladorTemplate,
  startLoading,
} from "../../actions/pasosCotizar";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { ModalLoading } from "../ModalLoading";

export const BotonContinuar = () => {
  const { acumulador, valorKms, loading, status } = useSelector(
    (state) => state.inputReducer
  );
  const { deal_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valueTemplate, setvalueTemplate] = useState("Si");
  const [modal, setmodal] = useState(false);

  const enviarDatos = () => {
    setmodal(true);
    dispatch(startLoading(true));
    enviarDatosAuto(
      acumulador.marca,
      acumulador.brand_id,
      acumulador.modelo,
      acumulador.model_id,
      acumulador.version,
      acumulador.version_id,
      acumulador.año,
      acumulador.gnc,
      valorKms,
      deal_id,
      valueTemplate,
      navigate,
      dispatch
    );
  };

  const handleChange = (event) => {
    setvalueTemplate(event.target.value);
    dispatch(startAcumuladorTemplate(event.target.value));
  };

  return (
    <div className="container-input-scroll">
      {valorKms !== undefined &&
      valorKms > 0 &&
      acumulador.gnc !== undefined &&
      acumulador.gnc !== "" ? (
        <div
          className="input-kilometros-tablet"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          <div className="btn-continuar-activo" onClick={enviarDatos}>
            Cotizar
          </div>
          <p
            className="labelModalManual"
            style={{ fontSize: "12px", marginTop: "10px", textAlign: "center" }}
          >
            ¿Enviar cotización?
          </p>
          <Grid>
            <RadioGroup
              defaultValue="Si"
              aria-labelledby="demo-controlled-radio-buttons-group"
              onChange={handleChange}
            >
              <Grid container alignItems="center" justifyContent="center">
                <FormControlLabel
                  value="Si"
                  control={<Radio size="small" />}
                  label="Si"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" />}
                  label="No"
                />
              </Grid>
            </RadioGroup>
          </Grid>
        </div>
      ) : (
        <div className="btn-continuar-disabled">Cotizar</div>
      )}
      <ModalLoading
        modal={modal}
        setmodal={setmodal}
        loading={loading}
        status={status}
      />
    </div>
  );
};
