import axios from "axios";
import { types } from "../types/type";

export const getDeal = (disptach, deal_id, navigate) => {
  axios
    .get(` https://infoauto-348420.rj.r.appspot.com/deal/${deal_id}/car`)
    .then((resp) => {
      let lengthOfObject = Object.keys(resp.data.car).length;
      if (lengthOfObject === 0) {
        navigate(`/${deal_id}`);
      } else {
        disptach(
          dataAuto(
            resp.data.car.brand,
            resp.data.car.model,
            resp.data.car.version,
            resp.data.car.year,
            resp.data.car.kms
          )
        );
      }

      // console.log(Object.keys(resp.data).length);
      // if (resp.data.brand !== "") {
      //   navigate(`/resumen/${deal_id}`);
      // }
    })
    .catch((e) => {
      console.log("ERROR", e.response.status);
    });
};

const dataAuto = (marca, modelo, version, año, kms) => ({
  type: types.DatosAuto,
  payload: {
    marca,
    modelo,
    version,
    año,
    kms,
  },
});

export const editarAuto = (editar) => ({
  type: types.EditarAuto,
  payload: {
    editar,
  },
});
