import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendKmsRedux } from "../../actions/pasosCotizar";
import Hidden from "@mui/material/Hidden";
import { BotonCargarTablet } from "../ButtonContinuar/BotonCargarTablet";

export const KilometrosCargar = () => {
  const { acumulador, valorKms } = useSelector((state) => state.inputReducer);

  const dispatch = useDispatch();

  const [mostrarSelect, setmostrarSelect] = useState(false);

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
    // dispatch(estaActivo("activo"));
    // dispatch(cerrarKms(false));
    // if (close === false || close === undefined) {
    //   dispatch(cerrarKms(true));
    //   console.log("ACA");
    // }
  };

  const handleInputChange = (e) => {
    dispatch(sendKmsRedux(Number(e.target.value)));
    // dispatch(startAcumuladorKilometros(Number(e.target.value)));
  };

  const activeEscribiendo = () => {
    //VALIDAR CON ESTA ACTIVO O CERRAR KMS
  };

  return (
    <div className="container-input-scroll">
      {acumulador !== undefined &&
      acumulador.version !== undefined &&
      acumulador.version !== "" ? (
        <>
          {valorKms !== undefined && valorKms !== "" ? (
            <>
              <Hidden mdDown>
                <div
                  className="btn-hecho animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  {valorKms} kms
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  className="btn-hecho animate__fadeIn"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  {valorKms} kms
                </div>
              </Hidden>
            </>
          ) : (
            <>
              <Hidden mdDown>
                <div
                  className="btn-activo animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  Kilometros
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  className="btn-activo animate__fadeIn"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  Kilometros
                </div>
              </Hidden>
            </>
          )}
        </>
      ) : (
        <>
          <Hidden mdDown>
            <div className="btn-disabled animate__fadeIn">Kilometros</div>
          </Hidden>
          <Hidden mdUp>
            <div
              className="btn-disabled animate__fadeIn"
              style={{ width: "160px", height: "33px", fontSize: "16px" }}
            >
              Kilometros
            </div>
          </Hidden>
        </>
      )}
      {/* {close === false ? ( */}
      {mostrarSelect ||
      (acumulador !== undefined &&
        acumulador.version !== undefined &&
        acumulador.version !== "") ? (
        <>
          <Hidden mdDown>
            <div className="input-kilometros" onClick={activeEscribiendo}>
              <input
                type="text"
                onChange={handleInputChange}
                placeholder="Ej: 24.000"
              />
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className="input-kilometros-tablet">
              <input
                type="text"
                onChange={handleInputChange}
                placeholder="Ej: 24.000"
              />
              <BotonCargarTablet />
            </div>
          </Hidden>
        </>
      ) : null}
    </div>
  );
};
